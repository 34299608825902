
.container-cta {
  background: #7990A9;
  padding: 34.25px 0;
  transition: all 0.5s;

  &:hover {
    background:#38506a !important;
  }

  .container-page {
    .title-cta {
      text-align: center;
      font-weight: 500;
      font-size: 47px;
      line-height: 44px;
      font-family: 'Cormorant Garamond';
      letter-spacing: 0px;
      color: #fff;
      text-decoration: underline;
    }

    @media (max-width: 560px) {
      padding-left: 0;
      padding-right: 0;
    }

    @media (max-width: 350px) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
